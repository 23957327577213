// src/pages/Library.js
import React, { useEffect, useState, useContext } from 'react';
import Navbar from '../components/Navbar';
import FilterPanel from './library/FilterPanel';
import PostsList from './library/PostsList';
import ApiService from '../services/ApiService';
import libraryBackground from '../assets/library_background.png';
import './Library.css';
import NsfwWarning from '../components/NsfwWarning';
import Loader from '../components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSearchParams } from 'react-router-dom'; // Import useSearchParams
import { AuthContext } from '../services/AuthContext';

const Library = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [viewAsAdmin, setViewAsAdmin] = useState(false);
  const [isNsfwAccepted, setIsNsfwAccepted] = useState(
    localStorage.getItem('isNsfwAccepted') === 'true'
  );
  const [showFilters, setShowFilters] = useState(false);
  const [postsPerLine, setPostsPerLine] = useState(2);
  const [availableTiers, setAvailableTiers] = useState([]);

  // Use useSearchParams to read and modify URL query parameters
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearchTerm = searchParams.get('search') || ''; // Get 'search' parameter from URL
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);


  // Initialize currentFilter from URL or default to 'all'
  const initialFilter = searchParams.get('filter') || 'all';
  const validFilters = ['all', 'unlocked', 'locked', 'patreon'];
  const [currentFilter, setCurrentFilter] = useState(
    validFilters.includes(initialFilter) ? initialFilter : 'all'
  );
  const { isAuthenticated, userData } = useContext(AuthContext);

  useEffect(() => {
    if (isNsfwAccepted) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const postsResponse = await ApiService.fetchPosts();
          const sortedPosts = postsResponse.data.posts.sort((a, b) => {
            const dateA = new Date(a.order_date || a.published_at);
            const dateB = new Date(b.order_date || b.published_at);
            return dateB - dateA;
          });
          setPosts(sortedPosts);

          try {
            console.log(isAuthenticated)
            if (isAuthenticated === true) {
              setIsAdmin(userData.isAdmin);
              setAccountName(userData.fullName);
            }
            if (isAuthenticated === false) {
              setIsAdmin(false);
              setAccountName('');
            }
          } catch (userError) {
            console.error('Error fetching user data:', userError);
            setIsAdmin(false);
            setAccountName('');
          }

          // Fetch tiers here
          const tiersResponse = await ApiService.getTiers();
          setAvailableTiers(tiersResponse.data);
        } catch (err) {
          console.error('Error fetching data:', err);
          setError(err);
          toast.error('Failed to fetch data. Please try again later.');
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [isNsfwAccepted,isAuthenticated, userData]);

  const handleAcceptNsfw = () => {
    setIsNsfwAccepted(true);
    localStorage.setItem('isNsfwAccepted', 'true');
  };

  const toggleFilterPanel = () => {
    setShowFilters((prevShowFilters) => !prevShowFilters);
  };

  const handleToggleEnabled = async (postId) => {
    try {
      if (!isAdmin) {
        toast.error('You need to be logged in as an admin to perform this action.');
        return;
      }

      const updatedPosts = posts.map((post) =>
        post.postId === postId ? { ...post, enabled: !post.enabled } : post
      );
      const postToUpdate = updatedPosts.find((post) => post.postId === postId);

      await ApiService.togglePost(postId, postToUpdate.enabled);

      setPosts(updatedPosts);
      toast.success(`Post has been ${postToUpdate.enabled ? 'enabled' : 'disabled'}.`);
    } catch (error) {
      console.error('Error updating post enabled status:', error);
      toast.error('Failed to update post status');
    }
  };

  // Update the URL when currentFilter changes
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    if (currentFilter === 'all') {
      params.delete('filter');
    } else {
      params.set('filter', currentFilter);
    }
    setSearchParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    if (searchTerm) {
      params.set('search', searchTerm);
    } else {
      params.delete('search');
    }
    setSearchParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  // Filter posts based on currentFilter
  // Filter posts based on currentFilter and then by searchTerm
  const filteredPosts = posts
    .filter((post) => {
      switch (currentFilter) {
        case 'all':
          return post.enabled;
        case 'unlocked':
          return post.unlocked === true && post.enabled;
        case 'locked':
          return !post.unlocked && post.enabled;
        case 'patreon':
          const hasPatreonButton =
            post.enabled &&
            (post.paid_content === true &&
              (post.url &&
                post.tiers.some((tier) =>
                  ['10477032', '8824971', '21786318', '8825009', '8652254'].includes(tier)
                )));
          return hasPatreonButton;
        default:
          return post.enabled;
      }
    })
    .map((post) => ({
      ...post,
      isSearchMatch: post.title.toLowerCase().includes(searchTerm.toLowerCase()),
    }))
    .sort((a, b) => {
      // Push matching posts to the top
      if (a.isSearchMatch && !b.isSearchMatch) return -1;
      if (!a.isSearchMatch && b.isSearchMatch) return 1;
      return 0; // Maintain original order for posts with the same match status
    });

  return (
    <>
      <Navbar isLoggedIn={!!accountName} accountName={accountName} />
      {!isNsfwAccepted && <NsfwWarning onAccept={handleAcceptNsfw} />}
      <div className="search-container" style={{ textAlign: 'center' }}>
        <input
          type="text"
          placeholder="Search posts by title..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ padding: '8px', borderRadius: '4px', width: '80%', maxWidth: '400px' }}
        />
      </div>
      <div
        className="library-container"
        style={{
          '--library-background-image': `url(${libraryBackground})`,
          filter: isNsfwAccepted ? 'none' : 'blur(8px)',
          pointerEvents: isNsfwAccepted ? 'auto' : 'none',
        }}
      >
        <ToastContainer />
        {isAdmin && (
          <div className="admin-toggle-container">
            <button onClick={() => setViewAsAdmin(!viewAsAdmin)}>
              {viewAsAdmin ? 'View as User' : 'View as Admin'}
            </button>
          </div>
        )}
        <FilterPanel
          showFilters={showFilters}
          toggleFilterPanel={toggleFilterPanel}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          postsPerLine={postsPerLine}
          setPostsPerLine={setPostsPerLine}
        />
        {loading ? (
          <Loader />
        ) : (
          <PostsList
            posts={filteredPosts}
            loading={loading}
            error={error}
            postsPerLine={postsPerLine}
            viewAsAdmin={viewAsAdmin}
            handleToggleEnabled={handleToggleEnabled}
            setPosts={setPosts}
            availableTiers={availableTiers}
            userData={userData}  // <-- pass userData here
          />
        )}
      </div>
    </>
  );
};

export default Library;
