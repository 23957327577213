// src/pages/Login.js

import React, { useState, useEffect,useContext,useRef   } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import config from './config.js';
import './Login.css'; // Import the CSS file for styling
import Loader from '../components/Loader'; // Import Loader
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import { AuthContext } from '../services/AuthContext';
import ApiService from '../services/ApiService'; // Import ApiService
import googleIcon from '../assets/google_logo.png'; // Add a Google icon
import patreonIcon from '../assets/patreon_logo.png'; // Add a Patreon icon
import discordIcon from '../assets/discord_logo.png'; // Add a Patreon icon
axios.defaults.withCredentials = true;

function Login() {
  const [kofiEmail, setKofiEmail] = useState('');
  const [kofiVerificationCode, setKofiVerificationCode] = useState('');
  const [linkedEmails, setLinkedEmails] = useState([]);
  const [unverifiedEmail, setUnverifiedEmail] = useState(null);
  // Email/Password login/signup fields
  const [loginEmail, setLoginEmail] = useState('');

  // For main account email verification
  const [mainVerificationCode, setMainVerificationCode] = useState('');
  const [username, setUsername] = useState('');

  const [loginPassword, setLoginPassword] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [tiers, setTiers] = useState([]); // State to store tiers
  const { isAuthenticated, userData } = useContext(AuthContext);
  const { setIsAuthenticated, setUserData } = useContext(AuthContext);

  // Passsword changes
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPasswordForChange, setNewPasswordForChange] = useState('');
  const [confirmNewPasswordForChange, setConfirmNewPasswordForChange] = useState('');
  const [newPasswordForSet, setNewPasswordForSet] = useState('');
  const [confirmNewPasswordForSet, setConfirmNewPasswordForSet] = useState('');
  const passwordRequirements = /^(?=.*[A-Z]).{8,}$/;


  // Timers
  const [kofiResendTimer, setKofiResendTimer] = useState(0); // For kofi verification if needed
  const [mainResendTimer, setMainResendTimer] = useState(0);

  const kofiResendInterval = useRef(null);
  const mainResendInterval = useRef(null);

  // Existing states and hooks
  const [showKofiMessage, setShowKofiMessage] = useState(false);
  const [kofiMessageContent, setKofiMessageContent] = useState('');
  const [activeTab, setActiveTab] = useState('login'); // Manage active tab state

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true); // Start loading
      try {
        if (isAuthenticated === true) {
          const [userResponse, tiersResponse] = await Promise.all([
            axios.get(`${config.apiBaseUrl}/api/auth/user`),
            ApiService.getTiers(),
          ]);
          setUserData(userResponse.data);

          // Set tiers
          setTiers(tiersResponse.data);
          // Extract linked Ko-fi emails if available
          if (userResponse.data.kofiEmails) {
            const verifiedEmails = userResponse.data.kofiEmails.filter(
              (email) => email.emailVerified
            );
            const unverified = userResponse.data.kofiEmails.find(
              (email) => !email.emailVerified
            );
            setLinkedEmails(verifiedEmails);
            setUnverifiedEmail(unverified || null);
          }
        }
      } catch (error) {
        console.error('Error fetching user data or tiers:', error);
        toast.error('Failed to fetch user data or tiers.');
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchUserData();
  }, [isAuthenticated, setUserData]);

  // Start main verification timer if user not verified
  useEffect(() => {
    if (isAuthenticated && userData && !userData.mailVerified) {
      setMainResendTimer(60);
      mainResendInterval.current = setInterval(() => {
        setMainResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(mainResendInterval.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } else {
      if (mainResendInterval.current) clearInterval(mainResendInterval.current);
      setMainResendTimer(0);
    }
  }, [isAuthenticated, userData]);

  useEffect(() => {
    const handleKofiBuyRedirect = () => {
      const currentUrl = window.location.href;
      if (currentUrl.includes('#kofibuy')) {
        if (isAuthenticated) {
          // Set message for authenticated user
          setShowKofiMessage(true);
        } else {
          // Set message for non-authenticated user
          setShowKofiMessage(true);
        }
      }
    };

    handleKofiBuyRedirect();
  }, [isAuthenticated]);

  const handleCloseMessage = () => {
    setShowKofiMessage(false);
    if (isAuthenticated) {
      navigate('/library'); // Redirect to library after acknowledging the message
    }
  };

const handlePatreonLogin = () => {
  window.location.href = `${config.apiBaseUrl}/api/auth/login`;
};
const handleGoogleLogin = () => {
  window.location.href = `${config.apiBaseUrl}/api/google/auth`; // Google login
};
const handleDiscordLogin = () => {
  window.location.href = `${config.apiBaseUrl}/api/discord/auth`; // Redirect to Discord OAuth
};
const handleSetUsernameGoogle = async () => {
  if (!username.trim()) {
    return toast.error('Please enter a valid username.');
  }

  setLoading(true);
  try {
    await axios.post(`${config.apiBaseUrl}/api/google/set-username-google`, { username }, { withCredentials: true });

    // Refresh user data to update username and verification status
    const userResponse = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
    setUserData(userResponse.data);
    toast.success('Username set successfully! Your account is now verified.');
  } catch (error) {
    console.error('Error setting username:', error);
    toast.error(error.response?.data?.error || 'Failed to set username.');
  } finally {
    setLoading(false);
  }
};

const handleSetUsernameDiscord = async () => {
  if (!username.trim()) {
    return toast.error('Please enter a valid username.');
  }

  setLoading(true);
  try {
    await axios.post(`${config.apiBaseUrl}/api/discord/set-username-discord`, { username }, { withCredentials: true });

    // Refresh user data to update username and verification status
    const userResponse = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
    setUserData(userResponse.data);
    toast.success('Username set successfully! Your account is now verified.');
  } catch (error) {
    console.error('Error setting username:', error);
    toast.error(error.response?.data?.error || 'Failed to set username.');
  } finally {
    setLoading(false);
  }
};

const handleChangePassword = async () => {
  if (!passwordRequirements.test(newPasswordForChange)) {
    return toast.error('Password must have at least 8 characters and 1 uppercase.');
  }
  if (newPasswordForChange !== confirmNewPasswordForChange) {
    return toast.error('Passwords do not match.');
  }

  setLoading(true);
  try {
    await axios.post(`${config.apiBaseUrl}/api/auth/change-password`, {
      currentPassword,
      newPassword: newPasswordForChange
    }, { withCredentials: true });
    toast.success('Password changed successfully.');
    setCurrentPassword('');
    setNewPasswordForChange('');
    setConfirmNewPasswordForChange('');
  } catch (error) {
    console.error(error);
    toast.error(error.response?.data?.error || 'Failed to change password.');
  } finally {
    setLoading(false);
  }
};
const handleSetPassword = async () => {
  if (!passwordRequirements.test(newPasswordForSet)) {
    return toast.error('Password must have at least 8 characters and 1 uppercase.');
  }
  if (newPasswordForSet !== confirmNewPasswordForSet) {
    return toast.error('Passwords do not match.');
  }

  setLoading(true);
  try {
    await axios.post(`${config.apiBaseUrl}/api/auth/set-password`, { newPassword: newPasswordForSet }, { withCredentials: true });
    toast.success('Password set successfully.');
    setNewPasswordForSet('');
    setConfirmNewPasswordForSet('');
    // Refresh user data to update hasPassword flag
    const userResponse = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
    setUserData(userResponse.data);
  } catch (error) {
    console.error(error);
    toast.error(error.response?.data?.error || 'Failed to set password.');
  } finally {
    setLoading(false);
  }
};

  const handleLogout = async () => {
    setLoading(true); // Start loading
    try {
      await axios.get(`${config.apiBaseUrl}/api/auth/logout`);
      setUserData(null);
      setIsAuthenticated(false)
      navigate('/login');
      toast.success('Logged out successfully.');
    } catch (error) {
      console.error('Logout failed', error);
      navigate('/login');
      toast.error('Logout failed.');
    } finally {
      setLoading(false); // Stop loading
    }
  };
  // Function to get the tier title
  const getTierTitle = () => {
    if (!userData || !userData.current_tier || tiers.length === 0) return 'None';
    const tier = tiers.find((t) => t.tierId === userData.current_tier);
    return tier ? tier.title : 'Unknown Tier';
  };

  const handleKofiLinkEmail = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kofi/link`,
        { email: kofiEmail },
        { withCredentials: true }
      );
      // console.log('Link email response:', response.data);
      toast.success('Verification code sent to your email.');
      // Update unverified email state
      setUnverifiedEmail({ email: kofiEmail });
      setKofiEmail('');
    } catch (error) {
      console.error('Error linking email:', error);
      let errorMessage = 'Failed to send verification code.';
      
      if (error.response && typeof error.response.data === 'string') {
        // Rate limit error might be a plain string
        errorMessage = error.response.data;
      } else if (error.response && error.response.data && error.response.data.error) {
        // If a JSON error is returned
        errorMessage = error.response.data.error;
      }
    
      toast.error(errorMessage);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleKofiVerifyEmail = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kofi/verify-email`,
        { email: unverifiedEmail.email, code: kofiVerificationCode },
        { withCredentials: true }
      );
      // console.log('Verify email response:', response.data);
      toast.success(response.data.message);
      setKofiVerificationCode('');
      // Refresh user data to get updated linked emails
      const userResponse = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
      setUserData(userResponse.data);
      if (userResponse.data.kofiEmails) {
        setLinkedEmails(
          userResponse.data.kofiEmails.filter((email) => email.emailVerified)
        );
        const unverified = userResponse.data.kofiEmails.find(
          (email) => !email.emailVerified
        );
        setUnverifiedEmail(unverified || null);
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      toast.error(
        error.response?.data?.error || 'Failed to verify email.'
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleKofiCancelVerification = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kofi/cancel-verification`,
        { email: unverifiedEmail.email },
        { withCredentials: true }
      );
      // console.log('Cancel verification response:', response.data);
      setUnverifiedEmail(null);
      setKofiVerificationCode('');
      toast.success('Verification process canceled.');
    } catch (error) {
      console.error('Error canceling verification:', error);
      toast.error(
        error.response?.data?.error || 'Failed to cancel verification.'
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleKofiResendVerification = async () => {
    setLoading(true); // Start loading !
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/kofi/resend-verification`,
        { email: unverifiedEmail.email },
        { withCredentials: true }
      );
      // console.log('Resend verification response:', response.data);
      toast.success('Verification code resent to your email.');
    } catch (error) {
      console.error('Error resending verification email:', error);
      toast.error(
        error.response?.data?.error || 'Failed to resend verification code.'
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handleToggleAdmin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/auth/toggle-admin`,
        {},
        { withCredentials: true }
      );
      // Update userData
      setUserData((prevUserData) => ({
        ...prevUserData,
        isAdmin: response.data.isAdmin,
      }));
      toast.success('Admin status updated.');
    } catch (error) {
      console.error('Error toggling admin status:', error);
      toast.error(
        error.response?.data?.error || 'Failed to toggle admin status.'
      );
    } finally {
      setLoading(false);
    }
  };
  // Handle Email Login
  const handleEmailLogin = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/api/auth/login`,
        { email: loginEmail, password: loginPassword },
        { withCredentials: true }
      );

      // If successful, fetch user data
      const userResponse = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
      setUserData(userResponse.data);
      setIsAuthenticated(true);
      toast.success('Logged in successfully.');
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error(error.response?.data?.error || 'Failed to log in.');
    } finally {
      setLoading(false);
    }
  };
  // Handle Email Sign Up
  const handleEmailSignUp = async () => {
    if (loginPassword !== confirmNewPasswordForSet) {
      return toast.error("Passwords do not match. Please try again.");
    }
  
    if (!passwordRequirements.test(loginPassword)) {
      return toast.error(
        "Password must have at least 8 characters and 1 uppercase."
      );
    }
    setLoading(true);
    try {
      await axios.post(`${config.apiBaseUrl}/api/auth/register`, { email: loginEmail, password: loginPassword }, { withCredentials: true });

      // After successful registration, user should be logged in automatically
      const userResponse = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
      setUserData(userResponse.data);
      setIsAuthenticated(true);
      toast.success('Account created and logged in successfully.');
    } catch (error) {
      console.error('Error signing up:', error);
      toast.error(error.response?.data?.error || 'Failed to sign up.');
    } finally {
      setLoading(false);
    }
  };

  // Main Email Verification Logic (separate from Kofi)
  const handleMainVerifyEmail = async () => {
    setLoading(true);
    try {
      // Verify email
      await axios.post(
        `${config.apiBaseUrl}/api/auth/verify-email`,
        { email: userData.email, code: mainVerificationCode },
        { withCredentials: true }
      );

      // Update username if provided (optional, adjust as needed)
      if (username.trim() !== '') {
        await axios.post(`${config.apiBaseUrl}/api/auth/update-username`, { username }, { withCredentials: true });
      }

      // Refresh user data
      const userResponse = await axios.get(`${config.apiBaseUrl}/api/auth/user`);
      setUserData(userResponse.data);
      // Update Ko-fi email states
      if (userResponse.data.kofiEmails) {
        const verifiedEmails = userResponse.data.kofiEmails.filter(
          (email) => email.emailVerified
        );
        const unverified = userResponse.data.kofiEmails.find(
          (email) => !email.emailVerified
        );

        setLinkedEmails(verifiedEmails); // Update verified emails
        setUnverifiedEmail(unverified || null); // Update unverified email
      }
      toast.success('Email verified and username set successfully.');
    } catch (error) {
      console.error('Error verifying main email:', error);
      toast.error(
        error.response?.data?.error || 'Failed to verify email.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleMainCancelVerification = () => {
    setMainVerificationCode('');
    setUsername('');
    toast.info('Verification cancelled.');
  };

  const handleMainResendVerification = async () => {
    setLoading(true);
    try {
      await axios.post(`${config.apiBaseUrl}/api/auth/resend-verification`, { email: userData.email }, { withCredentials: true });
      toast.success('Verification code resent to your email.');
      // Restart main timer
      setMainResendTimer(60);
      if (mainResendInterval.current) clearInterval(mainResendInterval.current);
      mainResendInterval.current = setInterval(() => {
        setMainResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(mainResendInterval.current);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      console.error('Error resending main verification email:', error);
      toast.error(
        error.response?.data?.error || 'Failed to resend verification code.'
      );
    } finally {
      setLoading(false);
    }
  };

  // New function to navigate to Admin Page
  const navigateToAdminPage = () => {
    navigate('/admin');
  };
  const isUserVerified = userData && userData.mailVerified;

  return (
    <div className="login-page">
      <Navbar isLoggedIn={!!userData} accountName={userData ? userData.fullName : ''} />
      <ToastContainer />
      {loading && <Loader />}
      {showKofiMessage && (
  <>
    <div className="kofi-message-overlay"></div>
    <div className="kofi-message">
      <h2>Thank you for your purchase&Support!</h2>
      {isAuthenticated ? (
        <>
          <p>
            You can download your items in the library !
            <br />
            Just before, have you already linked your Ko-fi account? If not, you can do it anytime in your user page !
          </p>
          <div className="kofi-message-buttons">
            <button onClick={() => navigate('/library')}>Bring me to the library, so I can download my stuff</button>
            <button onClick={() => setShowKofiMessage(false)}>I haven't linked my account yet, bring me to user page</button>
          </div>
        </>
      ) : (
        <>
          <p>
            Let's create an account on my website to unlock
            everything you ever bought !
          </p>
          <p>
            You purchased as a guest on Kofi ? No worries, you only need the mail you made the purchase with !
          </p>
          <div className="kofi-message-buttons">
          <button
            onClick={() => {
              setShowKofiMessage(false); // Close the message
              setActiveTab('signup'); // Switch to the "Sign Up" tab
            }}
          >
            Create My Account
            </button>
          <button
            onClick={() => {
              setShowKofiMessage(false); // Close the message
              setActiveTab('login');
            }}
          >
            I already have an account
            </button>
          </div>

        </>
      )}
    </div>
  </>
)}
      <div className="login-container">
        {userData ? (
          isUserVerified ? (
            // User Verified Panel
            <div className="welcome-container">
              <h1>Welcome, {userData.fullName}</h1>
              <div className="user-info">
                <p>
                  <strong>Email:</strong> {userData.email}
                </p>
                <p>
                  <strong>Full Name:</strong> {userData.fullName}
                </p>

                {userData.isAdmin && (
                  <p>
                    <strong>Is Admin:</strong> Yes
                    <button className="admin-page-button" onClick={handleToggleAdmin}>
                      Disable Admin
                    </button>
                    <button className="admin-page-button" onClick={navigateToAdminPage}>
                      To Admin Page
                    </button>
                  </p>
                )}
                {!userData.isAdmin && userData.canBeAdmin && (
                  <p>
                    <strong>Is Admin:</strong> No
                    <button className="admin-page-button" onClick={handleToggleAdmin}>
                      Enable Admin
                    </button>
                  </p>
                )}
              </div>

              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          ) : userData.hasGoogle ? (
            // 🔹 Google User Panel (No Email Verification Needed)
            <div className="unverified-panel">
              <h2>Welcome</h2>
              <input
                type="text"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <button onClick={handleSetUsernameGoogle}>Set My Username</button>
              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          ) : userData.hasDiscord ? (
            // 🔹 Discord User Panel (No Email Verification Needed)
            <div className="unverified-panel">
              <h2>Welcome</h2>
              <input
                type="text"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <button onClick={handleSetUsernameDiscord}>Set My Username</button>
              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          ) : (
            // Main Email Unverified Panel
            <div className="unverified-panel">
              <h2>Welcome</h2>
              <input
                type="text"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <p>
                Let's verify your mail for a full experience!
                <br />
                We already sent a verification code, enter it below:
              </p>
              <input
                type="text"
                placeholder="Enter verification code"
                value={mainVerificationCode}
                onChange={(e) => setMainVerificationCode(e.target.value)}
                required
              />
              <button onClick={handleMainVerifyEmail}>Verify my mail and set my username</button>
              <button onClick={handleMainCancelVerification}>Cancel Verification</button>
              <button onClick={handleMainResendVerification} disabled={mainResendTimer > 0}>
                {mainResendTimer > 0 ? `Resend code in ${mainResendTimer}s` : 'Resend verification code'}
              </button>
              <button className="logout-button" onClick={handleLogout}>
                Logout
              </button>
            </div>
          )
        ) : (
          <>
            <div className="login-panel">

              {/* Tabs */}
              <div className="tabs">
                <button
                  className={activeTab === 'login' ? 'tab active' : 'tab'}
                  onClick={() => setActiveTab('login')}
                >
                  Login
                </button>
                <button
                  className={activeTab === 'signup' ? 'tab active' : 'tab'}
                  onClick={() => setActiveTab('signup')}
                >
                  Create My Account
                </button>
              </div>

              {/* Tab Content */}
              <div className="tab-content">
                {activeTab === 'login' && (
                  <div className="login-form-container">
                    <h1>Continue With</h1>
                    <div className="login-buttons-container">
                      <button className="login-button" onClick={handlePatreonLogin}>
                        <img src={patreonIcon} alt="Patreon" />
                        Patreon
                      </button>
                      <button className="login-button" onClick={handleGoogleLogin}>
                        <img src={googleIcon} alt="Google" />
                        Google
                      </button>
                      <button className="login-button" onClick={handleDiscordLogin}>
                        <img src={discordIcon} alt="Discord" />
                        Discord
                      </button>
                    </div>
                    <h1>Or Email Login</h1>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      required
                    />
                    <input
                      type="password"
                      placeholder="Enter your password"
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                      required
                    />
                    <div className="button-group">
                      <button className="auth-button" onClick={handleEmailLogin}>
                        Login
                      </button>
                      <button
                        className="patreon-login-button"
                        onClick={() => navigate('/forgot-password')}
                      >
                        Forgot My Password
                      </button>
                    </div>
                  </div>
                )}

                {activeTab === 'signup' && (
                  <div className="login-form-container">
                      <div className="info-block">
                        <h2>Why Create an Account?</h2>
                        <ul>
                          <li>Claiming & Downloading Ko-fi Purchases</li>
                          <li>Claiming & Downloading Patreon Rewards</li>
                        </ul>
                        <p>
                          Thanks to your account, you'll have permanent access to everything you've ever received from me! 
                          Enjoy easy update downloads and more features coming soon!
                        </p>
                      </div>
                    <h1>Create your account with :</h1>
                    <div className="login-buttons-container">
                      <button className="login-button" onClick={handlePatreonLogin}>
                        <img src={patreonIcon} alt="Patreon" />
                        Patreon
                      </button>
                      <button className="login-button" onClick={handleGoogleLogin}>
                        <img src={googleIcon} alt="Google" />
                        Google
                      </button>
                    </div>
                    <h1>Or Sign Up</h1>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      required
                    />
                    <input
                      type="password"
                      placeholder="Enter your password"
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                      required
                    />
                    <input
                      type="password"
                      placeholder="Confirm your password"
                      value={confirmNewPasswordForSet}
                      onChange={(e) => setConfirmNewPasswordForSet(e.target.value)}
                      required
                    />
                        {/* Password Requirements Message */}
                    <p className="password-requirements">
                      Password must have at least 8 characters and 1 uppercase.
                    </p>
                    <div className="button-group">
                      <button className="auth-button" onClick={handleEmailSignUp}>
                        Sign Up
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {userData && isUserVerified && (
          <div className="kofi-container">
                <div className="welcome-container">
                  <div className="user-info">
                    <div className="info-block">
                      <h1>Kofi Informations</h1>
                      <h2>How can i claim my Kofi purchases ?</h2>
                      <ul>
                        <li>Simply link the mail you used to purchase on kofi !</li>
                        <li>Then you can download all your unlocked items in your library !</li>
                      </ul>
                      <p>
                        Once linked, you wont have to do it ever again ! All your purchases will be unlocked immediately and automatically !
                      </p>
                    </div>
                    {userData && (
                      <div className="linked-emails">
                        <h2>Linked Ko-fi Emails:</h2>
                        {linkedEmails.length > 0 ? (
                          <ul>
                            {linkedEmails.map((emailObj, index) => (
                              <li key={index}>
                                {emailObj.email} - Verified
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No Ko-fi emails linked yet.</p>
                        )}
                      </div>
                    )}
    
                    {/* Ko-fi Unverified Panel (if admin and unverified ko-fi email) */}
                    {unverifiedEmail && userData && (
                      <div className="unverified-panel">
                        <h2>Unverified Ko-fi Email</h2>
                        <p>
                          You are verifying: <strong>{unverifiedEmail.email}</strong>
                        </p>
                        <p>
                          If you want to verify another Ko-fi email, you must cancel or finish this one first.
                        </p>
                        <form onSubmit={handleKofiVerifyEmail}>
                          <input
                            type="text"
                            placeholder="Enter verification code"
                            value={kofiVerificationCode}
                            onChange={(e) => setKofiVerificationCode(e.target.value)}
                            required
                          />
                          <button type="submit">Verify Ko-fi Email</button>
                        </form>
                        <button onClick={handleKofiResendVerification}>Resend Verification Code</button>
                        <button onClick={handleKofiCancelVerification}>Cancel Verification</button>
                      </div>
                    )}
    
                    {!unverifiedEmail && userData && (
                      <div className="link-email-form">
                        <h2>Link a New Ko-fi Email</h2>
                        <form onSubmit={handleKofiLinkEmail}>
                          <input
                            type="email"
                            placeholder="Enter your Ko-fi email"
                            value={kofiEmail}
                            onChange={(e) => setKofiEmail(e.target.value)}
                            required
                          />
                          <button type="submit">Send Verification Code</button>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
          </div>
      )}
      {userData && isUserVerified && !userData.hasPatreon && (
          <div className="kofi-container">
                <div className="welcome-container">
                <div className="info-block">
                      <h1>Patreon Information</h1>
                      <h2>How can i claim my Patreon Rewards ?</h2>
                      <ul>
                        <li>Simply link your patreon account using the button below!</li>
                      </ul>
                      <p>
                        Once linked, you wont have to do it ever again ! All your rewards will be unlocked immediately and automatically !
                      </p>
                    </div>
                  
                  <div className="login-form-container">
                    <p>Please Link to your Patreon account to get all the benefits from it !</p>
                    <button className="patreon-login-button" onClick={handlePatreonLogin}>
                      Link to Patreon
                    </button>
                  </div>
                </div>
          </div>
      )}

      {userData && isUserVerified && userData.hasPatreon && (
          <div className="kofi-container">
                <div className="welcome-container">
                  <h1>Patreon Information</h1>
                  <div className="login-form-container">
                  <p><strong>Current Tier:</strong> {getTierTitle()}</p>
                  </div>
                </div>
          </div>
      )}
      {userData && isUserVerified && userData.hasPassword && (
          <div className="password-container">
            <div className="login-form-container" style={{ marginTop: '20px' }}>
              <h1>Change Your Password</h1>
              <input
                type="password"
                placeholder="Enter current password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Enter new password"
                value={newPasswordForChange}
                onChange={(e) => setNewPasswordForChange(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Confirm new password"
                value={confirmNewPasswordForChange}
                onChange={(e) => setConfirmNewPasswordForChange(e.target.value)}
                required
              />
              <p className="password-requirements">
                Password must have at least 8 characters & 1 uppercase.
              </p>
              <button className="auth-button" onClick={handleChangePassword}>Change Password</button>
            </div>
          </div>
      )}
      {userData && isUserVerified && !userData.hasPassword &&  (
            <div className="password-container">
              <div className="login-form-container" style={{ marginTop: '20px' }}>
                <h1>Set Your Password</h1>
                <input
                  type="password"
                  placeholder="Enter new password"
                  value={newPasswordForSet}
                  onChange={(e) => setNewPasswordForSet(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="Confirm new password"
                  value={confirmNewPasswordForSet}
                  onChange={(e) => setConfirmNewPasswordForSet(e.target.value)}
                  required
                />
                <p className="password-requirements">
                  Password must have at least 8 characters & 1 uppercase.
                </p>
                <button className="auth-button" onClick={handleSetPassword}>Set Password</button>
              </div>
            </div>
      )}

    </div>
  );
}
export default Login;
